<template>

  <div class="emt"> 
    
    <i class="icon-smile-medium smile"></i>
    
    <div class="txt">
      {{ props.text ?? $t('growers_view_search_empty_result') }}
    </div>
  </div>
  
</template>


<script setup lang="ts">

interface PageProps {
  text?: string | null
}

const props = withDefaults(defineProps<PageProps>(), {
  text: ''
})

</script>
 


<style scoped>
  .emt{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 300px;
    font-weight: bold;
  }

  .smile{
    font-size: 2rem;
    margin-bottom: 1rem;
  }

</style>